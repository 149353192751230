.shadow{
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #0005;
    z-index: 10;
}

.wrapper{
    position: fixed;
    top: 10%;
    z-index: 11;
    background-color: navajowhite;
    border: 3px solid peru;
    border-radius: 5px;
    width: 70%;
    max-width: 450px;
    margin: 0 15%;
    padding: 10px;
}