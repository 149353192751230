*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    vertical-align: top;
}

.boardContainerWhite{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.boardContainerBlack{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}

.tileRowWhite{
    display: flex;
    justify-content: center;
}

.tileRowBlack{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.fullTile{
    width: 60px;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.largeTile{
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.mediumTile{
    width: 35px;
    height: 35px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.smallTile{
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.fullPiece{
    width: default;
    height: default;
}

.largePiece{
    width: 40px;
    height: 40px;
}

.mediumPiece{
    width: 32px;
    height: 32px;
}

.smallPiece{
    width: 25px;
    height: 25px;
}

.boardLabel{
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-weight: bold; */
}

.fullVerticalLabel{
    width: 35px;
    height: 60px;
}

.fullHorizontalLabel{
    width: 60px;
    height: 35px;
}

.fullCornerLabel{
    width: 35px;
    height: 35px;
}

.largeVerticalLabel{
    width: 22px;
    height: 45px;
}

.largeHorizontalLabel{
    width: 45px;
    height: 22px;
}

.largeCornerLabel{
    width: 22px;
    height: 22px;
}

.mediumVerticalLabel{
    width: 20px;
    height: 35px;
    font-size: 0.9em;
}

.mediumHorizontalLabel{
    width: 35px;
    height: 20px;
    font-size: 0.9em;
}

.mediumCornerLabel{
    width: 20px;
    height: 20px;
}

.smallVerticalLabel{
    width: 20px;
    height: 25px;
    font-size: 0.8em;
}

.smallHorizontalLabel{
    width: 25px;
    height: 20px;
    font-size: 0.8em;
}

.smallCornerLabel{
    width: 20px;
    height: 20px;
}

.white{
    background-color: navajowhite;
}

.black{
    background-color: peru;
}

.active{
    background-color: dodgerblue;
}

.available{
    background-color: limegreen;
    border: 2px solid forestgreen;
}

.capture{
    background-color: firebrick;
    border: 2px solid lightcoral;
}

.lastMove{
    background-color: mediumpurple;
    border: 2px solid rebeccapurple;
}