*{
    box-sizing: border-box;
}

.mainPopup {
    /* width: 30%; */
    min-width: 250px;
    position: absolute;
    left: 20%;
    right: 20%;
    top: 10%;
    transform: translate(0%, 0);
    border: 3px solid #999999;
    z-index: 9;
    border-radius: 15px;
}
.innerContainer {
    width: 100%;
    max-height: 80%;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    background-color: oldlace;
    border-radius: 15px;
}