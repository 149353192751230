.block{
    color: navajowhite;
    background-color: #738;
    margin-top: -10px;
    padding: 20px 30px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}

.navlink, .loginlink, .logoutlink{
    color: #e6b276;
    border-right: 2px solid #738;
    border-left: 2px solid #738;
    border-radius: 5px;
    padding: 8px 10px !important;
}

.navlink:hover{
    color: navajowhite;
    border-right: 2px solid #ffdead;
    border-left: 2px solid navajowhite;
    border-radius: 5px;
}

.loginlink:hover{
    color: mediumseagreen;
    border-right: 2px solid mediumseagreen;
    border-left: 2px solid mediumseagreen;
    border-radius: 5px;
}

.logoutlink:hover{
    color: red;
    border-right: 2px solid red;
    border-left: 2px solid red;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}