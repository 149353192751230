.bar{
    color: navajowhite;
    background-color: #773388;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 8;
}

.mobileHolder{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #0000;
    z-index: 7;
}

.shadow{
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #0005;
    z-index: 6;
}

.menuIcon{
    color: navajowhite;
    background-color: #0000;
    margin: 15px;
    border: 0;
}

.outerMobileMenu{
    position: absolute;
    right: 0;
    z-index: 9;
}

.logo{
    font-family: Girassol, cursive;
    color: navajowhite;
    text-decoration: none;
}

.logo:hover{
    font-family: Girassol, cursive;
    color: #e6b276;
    text-decoration: none;  
}

.navlink, .loginlink, .logoutlink{
    color: #e6b276;
    border-top: 2px solid #773388;
    border-bottom: 2px solid #773388;
    /* font-family: Raleway; */
}

.navlink:hover{
    color: navajowhite;
    border-top: 2px solid #ffdead;
    border-bottom: 2px solid navajowhite;
    border-radius: 5px;
}

.loginlink:hover{
    color: mediumseagreen;
    border-top: 2px solid mediumseagreen;
    border-bottom: 2px solid mediumseagreen;
    border-radius: 5px;
}

.logoutlink:hover{
    color: red;
    border-top: 2px solid red;
    border-bottom: 2px solid red;
    border-radius: 5px;
}